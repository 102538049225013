
/* eslint-disable @typescript-eslint/camelcase */
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm
} from 'element-plus';
// import { Rules } from 'async-validator';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import dayjs from 'dayjs';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import VideoUploader, { UploadEvent } from '@/components/video-uploader/Index.vue';
import {
  getModels,
  getTags,
  getDirectors,
  uploadVideo,
  MAX_PER_PAGE
} from '@/services/api';
import { useCreateVideo, useUpdateVideo, useVideo } from '@/composables/api';
import { PartialVideo, VideoStatus } from '@/interfaces/Video';
import { Model } from '@/interfaces/Model';
import { Tag } from '@/interfaces/Tag';
import { Director } from '@/interfaces/Director';
import useFormErrors from '@/utils/validate';

const DEFAULT_FORM_VALUES: PartialVideo = {
  serial_number: '',
  cover: '',
  cover_source: '',
  cover_link: '',
  preview_video: '',
  preview_video_link: '',
  trailer: '',
  trailer_link: '',
  trailer_source: '',
  trailer_source_link: '',
  video: '',
  video_link: '',
  video_source: '',
  video_source_link: '',
  title: '',
  description: '',
  published_at: '',
  status: VideoStatus.DRAFT,
  models: [],
  model_ids: [],
  directors: [],
  tags: [],
  views: 0,
  is_free: 0
};

const rules = {
  serial_number: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  cover: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  preview_video: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  trailer_source: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  video_source: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  title: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  description: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  published_at: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  model_ids: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  views: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    VideoUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const videoId = useRoute().params.id as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { formErrors, bindFormItemError } = useFormErrors();
    const previewFileList = ref([]);
    watch(() => formValues.value.preview_video, (preview_video) => {
      previewFileList.value = preview_video
        ? [{ name: preview_video }]
        : [];
    });

    // format publish at
    watch(() => formValues.value.published_at, (published_at) => {
      formValues.value.published_at = dayjs(published_at).format('YYYY-MM-DD hh:mm:ss');
    });

    const models = ref<Model[]>([]);
    const directors = ref<Director[]>([]);
    const tags = ref<Tag[]>([]);

    const { isLoading: isCreatedLoading, mutate: create } = useCreateVideo();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateVideo();

    const fetchModels = async() => {
      models.value = (await getModels({ query: { per_page: MAX_PER_PAGE } })).data;
    };

    const fetchDirectors = async() => {
      directors.value = (await getDirectors()).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { per_page: MAX_PER_PAGE } })).data;
    };

    const { data, dataUpdatedAt } = useVideo({ videoId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    onMounted(async() => {
      fetchModels();
      fetchDirectors();
      fetchTags();
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.cover = path;
    };

    const handlePreviewVideoChange = async({ raw }: UploadFile) => {
      const { data } = await uploadVideo({ data: { video: raw } });

      formValues.value.preview_video = data.path;
    };

    const handlePreviewVideoRemove = () => {
      formValues.value.preview_video = '';
    };

    const handleTrailerUpload = ({ path }: UploadEvent) => {
      formValues.value.trailer_source = path;
    };

    const handleVideoUpload = ({ path }: UploadEvent) => {
      formValues.value.video_source = path;
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ videoId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.go(-1);
              },
              onError(error: any) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.replace({
                name: 'list-videos'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      formRef,
      formValues,
      previewFileList,
      models,
      directors,
      tags,
      rules,
      handleCoverCrop,
      handlePreviewVideoChange,
      handlePreviewVideoRemove,
      handleTrailerUpload,
      handleVideoUpload,
      VideoStatus,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      bindFormItemError
    };
  }
});
