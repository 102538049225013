import { PartialModel } from './Model';
import { PartialDirector } from './Director';
import { PartialPhoto } from './Photo';
import { PartialTag } from './Tag';

export enum VideoStatus {
  DRAFT = 'draft',
  PROCESSING = 'processing',
  SCHEDULING = 'scheduling',
  PUBLISHED = 'published',
}

export interface Video {
  id: number
  serial_number: string
  title: string
  description: string
  video: string
  video_link: string
  video_source: string
  video_source_link: string
  trailer: string
  trailer_link: string
  trailer_source: string
  trailer_source_link: string
  video_duration: number
  views: number
  cover: string
  cover_source: string
  cover_link: string
  preview_video: string
  preview_video_link: string
  published_at: string
  status: VideoStatus
  created_at: string
  updated_at: string
  original_views: number
  likers_count: number
  dislikers_count: number
  model_ids: number[]
  models: PartialModel[]
  directors: PartialDirector[]
  tags: PartialTag[]
  photos: PartialPhoto[]
  is_free: number
}

export type PartialVideo = Partial<Video>;
