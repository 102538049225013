
import {
  defineComponent,
  toRefs,
  ref,
  watch,
  PropType,
  onMounted,
  onUnmounted
} from 'vue';
import { ElMessage } from 'element-plus';
import { v4 as uuidV4 } from 'uuid';
import { UploadFilled, VideoPause, VideoPlay } from '@element-plus/icons';
import {
  FileResultHandler,
  UploadFile,
  ElFile
} from 'element-plus/es/components/upload/src/upload.type';

import { UploadToCloudResponse } from '@/services/api';
import { Uploader, UploadStatus } from './utils';

export type UploadEvent = UploadToCloudResponse & {
  file: ElFile
  index: number
};

export interface VideoItem {
  file?: ElFile
  uploader?: Uploader
  progress?: number
  status?: UploadStatus
  previewSource?: string
  uuid?: string
  path?: string
}

const StatusMap: Record<UploadStatus, string> = {
  checking: 'Checking file status...',
  executing: 'Uploading...',
  pause: 'Pause',
  processing: 'Processing file...',
  finished: 'Upload finished'
};

export default defineComponent({
  components: {
    UploadFilled,
    VideoPause,
    VideoPlay
  },
  props: {
    type: {
      type: String,
      required: true
    },
    sources: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    upload: (_event: UploadEvent) => true
  },
  setup(props, { emit }) {
    const { sources, multiple } = toRefs(props);
    const videoList = ref<VideoItem[]>(
      sources.value.map(source => ({
        progress: source ? 100 : 0,
        previewSource: source
      }))
    );
    watch(sources, (sources) => {
      videoList.value = sources.map(source => ({
        progress: source ? 100 : 0,
        previewSource: source
      }));
    });

    const pauseUpload = ({ index }: { index: number }) => {
      const { uploader } = videoList.value[index];
      uploader.pause();
    };

    const resumeUpload = ({ index }: { index: number }) => {
      const { uploader } = videoList.value[index];
      uploader.resume();
    };

    const handleOffline = () => {
      ElMessage.warning('Offline! Please checking the network cables, modem and router or reconnecting to Wi-Fi.');

      videoList.value.forEach(videoItem => {
        if (videoItem.uploader && videoItem.status === UploadStatus.EXECUTING) {
          videoItem.uploader.pause();
        }
      });
    };

    const handleOnline = () => {
      ElMessage.success('Online! Back to work!');

      videoList.value.forEach(videoItem => {
        if (videoItem.uploader && videoItem.status === UploadStatus.PAUSE) {
          videoItem.uploader.resume();
        }
      });
    };

    onMounted(() => {
      window.addEventListener('offline', handleOffline);
      window.addEventListener('online', handleOnline);
    });

    onUnmounted(() => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    });

    const handleFileChange: FileResultHandler = async({ raw: file }: UploadFile) => {
      if (!navigator.onLine) {
        handleOffline();
        return;
      }

      let index = 0;
      const uploader = new Uploader(
        file,
        {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
          meta: {
            type: props.type
          },
          onProgress(percentage) {
            videoList.value[index].progress = percentage;
          },
          onStatusChange(status) {
            videoList.value[index].status = status;
            console.log(status);
          },
          onSuccess(response) {
            videoList.value[index].previewSource = response.source;
            videoList.value[index].path = response.path;

            emit('upload', {
              ...response,
              file,
              index
            } as UploadEvent);
          }
        }
      );

      const videoItem: VideoItem = {
        file,
        progress: uploader.progress,
        status: uploader.status,
        uuid: uuidV4(),
        uploader
      };

      if (!multiple.value) {
        videoList.value[index] = videoItem;
      } else {
        const length = videoList.value.push(videoItem);
        index = length - 1;
      }

      uploader.start();
    };

    return {
      videoList,
      UploadStatus,
      handleFileChange,
      pauseUpload,
      resumeUpload,
      StatusMap
    };
  }
});
