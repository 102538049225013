/* eslint-disable @typescript-eslint/camelcase */
import {
  checkFileOnCloud,
  checkFileOnServer,
  uploadToCloud,
  checkChunkList,
  uploadFile,
  UploadToCloudResponse,
  FileInfo
} from '@/services/api';
import { createFileHash } from '@/utils/file-hashes';

async function checkFileIsUploaded(fileInfo: FileInfo & { size: number }) {
  console.log('checkFileOnCloud');
  const onCloudResponse = await checkFileOnCloud({
    query: fileInfo
  });
  if (onCloudResponse.size === fileInfo.size) {
    // 雲端已存在此檔案，提示已上傳完成
    console.log('雲端已存在此檔案，提示已上傳完成', onCloudResponse);
    return onCloudResponse;
  };

  console.log('checkFileOnServer');
  const onServerResponse = await checkFileOnServer({
    query: fileInfo
  });
  if (onServerResponse.size === fileInfo.size) {
    // 檔案存在，直接上傳到雲端
    console.log('檔案存在，直接上傳到雲端', onServerResponse);

    const toCloudResponse = await uploadToCloud({
      data: fileInfo
    });
    if (toCloudResponse.error) {
      throw new Error(toCloudResponse.error);
    }

    console.log('上傳雲端成功', toCloudResponse);
    return toCloudResponse;
  };

  return false;
}

export enum UploadStatus {
  CHECKING = 'checking',
  EXECUTING = 'executing',
  PAUSE = 'pause',
  PROCESSING = 'processing',
  FINISHED = 'finished'
}

interface FileMeta {
  type: string
  uuid: string
  dir: string
  ext: string
}

interface UploadOptions {
  meta: FileMeta
  onStatusChange?(status: UploadStatus): void
  onProgress?(percentage: number): void
  onSuccess?(response: UploadToCloudResponse): void
  onError?(error: Error): void
}

export class Uploader {
  status = UploadStatus.PAUSE;
  progress = 0;
  file: File;
  options: UploadOptions

  constructor(file: File, options: UploadOptions) {
    this.file = file;
    this.options = {
      ...options,
      meta: {
        dir: `video_${options.meta.type}s`,
        ext: file.name.split('.').pop() || '',
        ...options.meta
      }
    };
  }

  setStatus(status: UploadStatus) {
    const { onStatusChange } = this.options;

    this.status = status;
    onStatusChange && onStatusChange(this.status);
  }

  setProgress(percentage: number) {
    const { onProgress } = this.options;

    this.progress = percentage;
    onProgress && onProgress(this.progress);
  }

  setSuccess(response: UploadToCloudResponse) {
    const { onSuccess } = this.options;

    this.setProgress(100);
    this.setStatus(UploadStatus.FINISHED);
    onSuccess && onSuccess(response);
  }

  async setFileHash() {
    if (this.options.meta.uuid) {
      this.setStatus(UploadStatus.EXECUTING);
      return;
    };

    this.setStatus(UploadStatus.CHECKING);

    this.options.meta.uuid = await createFileHash(
      this.file
      // (percentage) => {
      //   this.setProgress(percentage / 20);
      // }
    );

    this.setStatus(UploadStatus.EXECUTING);
  }

  pause() {
    this.setStatus(UploadStatus.PAUSE);
  }

  async start() {
    if (this.status === UploadStatus.FINISHED) return;

    await this.setFileHash();

    const { meta } = this.options;
    const isUploadResponse = await checkFileIsUploaded({
      ...meta,
      size: this.file.size
    });
    if (isUploadResponse) {
      this.setSuccess(isUploadResponse);
      return;
    }

    this.resume();
  }

  async resume() {
    if (this.status === UploadStatus.FINISHED) return;

    // 檢查分塊存在與否，並上傳到服務器
    console.log('checkChunkList');

    await this.setFileHash();

    const { meta, onError } = this.options;

    // 設定要分割的區塊大小，檔案在 1GB 以上分為 10MB 為一區塊, 1GB 以下則分為 900KB 為一區塊，此區塊大小可任意修改
    const fileSize = this.file.size;
    const chunkSize = 1024 * (fileSize > 1024000000 ? 10240 : 900);
    const chunkTotal = Math.max(1, Math.ceil(fileSize / chunkSize));

    // 未上傳的區塊列表
    const { block_arr: unUploadChunkList } = await checkChunkList({
      query: {
        ...meta,
        block_tot: chunkTotal
      }
    });

    if (!unUploadChunkList.length) {
      unUploadChunkList.push(chunkTotal - 1);
    }

    const uploadToServer = async(start = 0, index = 0) => {
      console.log('uploadToServer');

      if (this.status === UploadStatus.PAUSE) {
        return;
      }

      const MAX_PARALLEL_CALL = 10;
      const chunkId = Math.round(start / chunkSize);

      // 要上傳的區塊需在未上傳的 block_arr 內，才可上傳
      if (unUploadChunkList.includes(chunkId)) {
        console.log('head chunkId: ' + chunkId);

        const fileName = this.file.name;
        const fileResponse = await uploadFile({
          data: {
            filename: fileName,
            type: meta.ext,
            block_id: chunkId,
            block_tot: chunkTotal,
            block: this.file.slice(start, start + chunkSize),
            dir: meta.dir,
            oriname: fileName,
            ext: meta.ext,
            start,
            uuid: meta.uuid
          }
        });

        const NewProgress = Math.round((fileResponse.temp_file_count / chunkTotal) * 100);
        if (NewProgress > this.progress) {
          this.setProgress(NewProgress);
        };

        if (fileResponse.block_tot) {
          // 若返回 block_tot，表示全部區款都已上傳成功
          // 接著上傳到 s3
          console.log('upload to server ended');

          this.setStatus(UploadStatus.PROCESSING);

          const toCloudResponse = await uploadToCloud({ data: meta });
          if (toCloudResponse.error) {
            onError && onError(new Error(toCloudResponse.error));
            return;
          }

          console.log('上傳雲端成功', toCloudResponse);

          this.setSuccess(toCloudResponse);
          return;
        }

        // 無 block_tot，繼續上傳區塊
        if (chunkId && (chunkId % MAX_PARALLEL_CALL === 0)) {
          console.log('ajax chunkId: ' + chunkId);

          uploadToServer(start + chunkSize, index); // 遞迴
        }
      }

      // 目前執行緒一次執行10個請求
      if (!chunkId || (chunkId % MAX_PARALLEL_CALL !== 0)) {
        console.log('tail chunkId: ' + chunkId);

        uploadToServer(start + chunkSize, index); // 遞迴
      }
    };

    const start = unUploadChunkList[0] * chunkSize;
    uploadToServer(start);
  }
}
